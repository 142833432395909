<template>
    <div class="relative flex w-full flex-col bg-white">
        <button type="button" @click="closeModal" class="btn-close-modal absolute right-5 top-5">
            <font-awesome-icon class="h-[27px] w-[27px] text-black" :icon="['fas', 'xmark']" />
        </button>

        <!--<div class="mx-auto my-4 flex w-[200px] items-center px-4 text-announcementgray">
            Pay with Wallet
            <label class="relative ml-4 flex cursor-pointer items-center">
                <input
                    id="is_paywithwallet"
                    name="is_paywithwallet"
                    v-model="isPayWithWallet"
                    type="checkbox"
                    class="peer sr-only text-announcementgray" />
                <div
                    class="peer h-6 w-9 rounded-full bg-gray-400 after:absolute after:left-[4px] after:top-[5px] after:h-[14px] after:w-[14px] after:rounded-full after:border-white after:bg-white after:transition-all after:content-[''] peer-checked:bg-graycontrol peer-checked:after:translate-x-full peer-checked:after:border-white"></div>
            </label>
        </div>-->
        <div class="my-4 flex w-auto flex-col items-center justify-center px-2">
            <div class="my-4 flex w-auto flex-col items-center justify-center px-2">
                <div class="relative">
                    <div class="justify-center">
                        <div
                            v-if="!props.creator.avatar"
                            class="mx-auto mb-[5px] h-[80px] w-[80px] rounded-full">
                            <font-awesome-icon
                                icon="fa-solid fa-user-circle"
                                class="h-[80px] w-[80px] rounded-full bg-gray-300 text-neutral-700" />
                        </div>
                        <img
                            v-else
                            alt="avatar"
                            :src="props.creator.avatar"
                            class="mx-auto mb-[5px] h-[80px] w-[80px] rounded-full" />
                        <div class="flex items-center justify-center">
                            <div class="flex flex-col pl-[11px] text-center">
                                <span
                                    class="mb-[5px] block truncate text-[18px] font-medium capitalize leading-[18px] tracking-[.54px] text-graycontrol">
                                    {{ props.creator.name }}
                                </span>
                                <span class="relative block text-[14px] text-graycontrol">
                                    @{{ props.creator.profile_url }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="mb-[17px] max-w-[330px] pt-[25px] text-left text-[14px] leading-normal tracking-wide text-graycontrol">
                    <p>You successfully purchased this video</p>
                </div>
                <p v-if="insufficientFunds" class="mb-10 text-red-900">
                    Not enough funds in wallet, you can add more
                    <Link :href="route('profile.wallet')" class="font-bold">here</Link>.
                </p>
                <p v-else-if="errorMessage" class="mb-10 text-red-900">{{ errorMessage }}</p>
                <font-awesome-icon :icon="['fas', 'spinner']" spin v-if="loading" />

                <AppButton
                    class="mx-auto block rounded-[6px] bg-mintgreen px-[16px] py-[15px] text-[16px] font-medium tracking-[.48px] text-black"
                    v-else
                    @click="closeModal"
                    :text="`CLOSE`"
                    ui="primary" />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import { Link } from '@inertiajs/vue3'
    import AppButton from '@/Components/ui-components/AppButton.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

    const props = defineProps({
        post: { type: Object, default: {} },
        creator: { type: Object, default: {} },
    })

    const emit = defineEmits(['onPurchasePost', 'closeModal'])
    function closeModal() {
        emit('closeModal')
    }
    const isPayWithWallet = ref(false)
    const loading = ref(false)
    const errorMessage = ref('')
    const insufficientFunds = ref(false)

    function purchasePost() {
        loading.value = true
        errorMessage.value = ''
        insufficientFunds.value = false
        axios
            .post(route('billing.purchase', props.post), {
                isPayWithWallet: isPayWithWallet.value ? 1 : 0,
            })
            .then(response => {
                if (response.data.error == 'NeedAdditionalFundsException') {
                    insufficientFunds.value = true
                } else if (response.data.error) {
                    errorMessage.value = response.data.error
                } else {
                    emit('onPurchasePost')
                }
            })
            .catch(function (error) {
                if (error.response) {
                    errorMessage.value = error.response.data.message
                }
            })
            .finally(() => (loading.value = false))
    }
</script>

<style scoped>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
</style>
